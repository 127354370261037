import React from "react";

import "../css/footer.css";

function Footer() {
  return (
    <div>
      <div className="about_us">
        <div className="IEEE-SJCE">
          <h1>SNB FOUNDATION</h1>
        </div>
        <div className="contact_us">
          <h1>ADDRESS</h1>
          <p>
            Amhara, Bihta, Patna
            <br />
            Phone: +91-9939004752
            <br />
            Email: info@snbcollege.com
          </p>
        </div>
        {/* <div className="importent_links">
          <h1>IMPORTANT LINKS</h1>
          <a href="https://jssstuniv.in/">JSS STU</a>
          <a href="https://www.ieee.org/">IEEE.org</a>
          <a href="https://wie.ieee.org/">IEEE WIE</a>
          <a href="https://standards.ieee.org/">IEEE Standrads</a>
          <a href="https://ieeexplore.ieee.org/Xplore/home.jsp">
            IEEE XploreDigital Library
          </a>
          <a href="https://eds.ieee.org/">IEEE EDS</a>
          <a href="https://ieeebangalore.org/">IEEE-Bangalore section</a>
          <a href="http://www.ieee-ras.org/">IEEE RAS</a>
        </div> */}
        <div className="follow_us">
          <h1>FOLLOW US</h1>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
            integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
            crossorigin="anonymous"
          />

          <ul>
            <li>
              <i className="fab fa-instagram icon"></i>
            </li>
            <li>
              <i className="fab fa-facebook-f icon"></i>
            </li>
            <li>
              <i className="fab fa-linkedin-in icon"></i>
            </li>
            <li>
              <i className="fab fa-youtube icon"></i>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer">
        <p>
          Copyrights © 2024 SNB-Foundation | Developed by the Abhijeet Bharti
        </p>
        {/* <!-- <hr> --> */}
      </div>
    </div>
  );
}

export default Footer;
