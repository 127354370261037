import AOS from "aos";
import { React, useEffect } from "react";

import "../css/sponsor.css";
import "aos/dist/aos.css";

export default function Sponsor() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set the duration of the animation
      once: true, // Only animate once
    });
  }, []);
  return (
    <div className="slide_container">
      <h1 className="heading" data-aos="fade">
        Trusted
      </h1>
      <hr className="hr-1" />
      <div className="scroll-parent">
        <div className="scroll-element primary">
          <img src="/photos/snbImages/brand1.png" alt="damilola jerugba" />
          <img src="/photos/snbImages/brand2.png" alt="tosin jerugba" />
          <img src="/photos/snbImages/brand3.png" alt="fiyin jerugba" />
        </div>
        <div className="scroll-element secondary">
          <img src="/photos/snbImages/brand1.png" alt="damilola jerugba" />
          <img src="/photos/snbImages/brand2.png" alt="tosin jerugba" />
          <img src="/photos/snbImages/brand3.png" alt="fiyin jerugba" />
        </div>
      </div>
    </div>
  );
}
