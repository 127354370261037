import AOS from "aos";
import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../css/milestone_events.css";
import "aos/dist/aos.css";
import { events } from "../data/courseData";

function Milestone_events() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set the duration of the animation
      once: true, // Only animate once
    });
  }, []);
  const navigate = useNavigate();

  const handleCardClick = (event) => {
    navigate(`/course-details/${event.id}`, {
      state: event,
    });
  };

  return (
    <>
      <div className="event" id="event_part">
        <h1 className="heading" data-aos="fade">
          COURSES IN OUR COLLEGE
        </h1>
        <hr className="hr-1" />
      </div>
      <div className="outer_milestone_container">
        <div className="milestone_container">
          {events.map((event) => (
            <div
              className="card"
              key={event.id}
              data-aos="zoom-in"
              onClick={() => handleCardClick(event)}
            >
              <div className="image">
                <img src={event.img} alt={event.title} />
              </div>
              <div className="content">
                <h3>{event.title}</h3>
                <p style={{ color: "white" }}>
                  <b>Course Duration : </b>
                  {event.courseDuration}
                </p>
                <p style={{ color: "white" }}>
                  <b>Eligibility : </b>
                  {event.eligibility}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Milestone_events;
