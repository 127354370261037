import React from "react";
import Navbar from "./Navbar";
import "../css/antiragging.css";
import Footer from "./Footer";

function AntiRaggingHelpDesk() {
  return (
    <>
      <Navbar />
      <div className="help-desk-container">
        <h2 style={{ color: "#0095da" }}>Anti-Ragging Help Desk</h2>
        <hr className="hr-1" />
        <div className="card-container">
          <div className="card">
            <h3>Download Anti-Ragging Self Declaration Form</h3>
            <a
              href="/AntiRagging Declaration Form to be filled by students.pdf"
              download
            >
              Download Form
            </a>
          </div>
          <div className="card">
            <h3>Download UGC Regulations on Curbing Ragging</h3>
            <a
              href="/Crubing The Menace of Ragging in Higher Education.pdf"
              download
            >
              Download UGC Regulations
            </a>
          </div>
          <div className="card">
            <h3>Report Ragging Incidents</h3>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfeLwDipIqkiYcmp-uzphoMUO0HuA3A3lRrnu6nuhFcHv_paA/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Report Here
            </a>
          </div>
        </div>
        {/* Moving message section */}
        <div className="moving-message">
          <p>
            Say NO to ragging! Respect your peers and maintain a safe
            environment for everyone.
          </p>
        </div>
      </div>
    </>
  );
}

export default AntiRaggingHelpDesk;
