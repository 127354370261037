import AOS from "aos";
import { React, useEffect } from "react";

import "../css/event.css";
import "aos/dist/aos.css";

function Event() {
  useEffect(() => {
    AOS.init({
      duration: 900, // Set the duration of the animation
      once: true, // Only animate once
    });
  }, []);
  const handleButtonClick = () => {
    window.location.href =
      "https://drive.google.com/file/d/1ESOjdVIgwi6UNhy4r_kQT_JdSEP8-8nh/view?usp=sharing";
  };

  return (
    <div>
      <div className="event" id="event_part">
        <h1 className="heading" data-aos="fade">
          SNB College of Pharmacy and Management
        </h1>
        <hr className="hr-1" />
      </div>
      <div className="new_event" id="new_event_1" data-aos="zoom-in">
        <img src="/PharmacyManagementLogo.png" alt="" data-aos="zoom-in" />

        <div className="inside_box">
          <h1> About Us</h1>
          <p data-aos="zoom-in">
            Welcome to SNB College of Pharmacy and Management which runs under
            the aegis of &quot;SNB Foundation Trust”. SNB College of Pharmacy
            and Management has a proclaimed objective of becoming a centre of
            excellence for advanced studies and research in pharmaceutical
            sciences. We are specially designed institution equipped with all
            the modern infrastructure and facilities. The institution is
            imparting Education covering all the norms and standards of BBA /
            BCA. Course. We aspire for progressing towards future by the means
            of helping people achieve, through education.
            <br />
            <b>We</b>, as an establishment, guide, educate, encourage study,
            research, inspire students, personnel, and people associated with
            the body, towards a goal to achieve stability, acknowledgement,
            knowledge &amp; information for an advanced and creative future. Our
            Bachelor program is accredited by AICTE.
            <br />
            <b>For</b>, our advancement towards IT field, we have provided
            various institutions a helping hand, by the way of promoting the
            scholars to follow/make a path into Service Sector and extensive
            research job offers to the students.
            <br />
          </p>

          <button
            className="btn"
            onClick={handleButtonClick}
            style={{ width: "300px" }}
          >
            Download Prospectus 2024 !!
          </button>
        </div>
      </div>
    </div>
  );
}

export default Event;
