import AOS from "aos";
import { React, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "../css/pastevent.css";
import "aos/dist/aos.css";
import "react-vertical-timeline-component/style.min.css";

function Past_events() {
  const pastEvents = [
    {
      id: 1,
      title: "Shreya",
      img: "photos/snbImages/T1.jpg",
      content: `It was a great experience studying at SNB College, a memory to cherish for lifetime. My experience at SNB was full of learning and grooming. It gave me an opportunity to meet different kind of people and learnt many things from them. I am thankful to all the faculties, mentors and entire department for providing us with quality education.`,
    },
    {
      id: 2,
      title: "Shweta",
      img: "photos/snbImages/T2.jpg",
      content: `SNB College is a place where you can find an amalgamation of learning. I feel great studying at SNB College as it gives great opportunity as well as support from faculties and placement officers.`,
    },
    {
      id: 3,
      title: "Rahul",
      img: "photos/snbImages/T3.jpg",
      content: `I thank SNB College & faculties for putting in all the efforts to groom us and make us Perfect. It was a wonderful experience at SNB College.`,
    },
    {
      id: 4,
      title: "Rishi",
      img: "photos/snbImages/T4.jpg",
      content: `I am grateful to SNB College - both the faculty and the Training & Placement Department. They've made efforts ensuring maximum number of placed students. The college started grooming us for placements in the first few months including courses such as Professional Excellence Program and Professional Skills Enhancement.`,
    },
  ];
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set the duration of the animation
      once: true, // Only animate once
    });
  }, []);
  return (
    <>
      <div className="past_events_bigscreen">
        <h1 className="heading" data-aos="fade">
          WHAT OUR HAPPY STUDENT'S SAY
        </h1>
        <hr className="hr-1" />
        <VerticalTimeline lineColor={"rgb(147, 169, 187)"}>
          {pastEvents.map((event) => {
            return (
              <>
                {event.id % 2 === 1 ? (
                  <VerticalTimelineElement
                    key={event.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "#414a4c ",
                      color: "#fff",
                      right: "5%",
                      borderRadius: "20px",
                    }}
                    contentArrowStyle={{
                      borderRight: "#414a4c ",
                    }}
                    date={event.date}
                    icon={<img className="event_img" src={event.img} alt="" />}
                  >
                    <h3
                      style={{ fontSize: "1.7rem" }}
                      className="vertical-timeline-element-title"
                    >
                      {event.title}
                    </h3>
                    <p style={{ fontSize: "1rem", textAlign: "justify" }}>
                      {event.content}
                    </p>
                  </VerticalTimelineElement>
                ) : (
                  <VerticalTimelineElement
                    key={event.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "#414a4c ",
                      color: "#fff",
                      left: "5%",
                      borderRadius: "20px",
                    }}
                    contentArrowStyle={{
                      borderRight: "#414a4c ",
                    }}
                    date={event?.date}
                    icon={<img className="event_img" src={event.img} alt="" />}
                  >
                    <h3
                      style={{ fontSize: "1.7rem" }}
                      className="vertical-timeline-element-title"
                    >
                      {event.title}
                    </h3>
                    <p style={{ fontSize: "1rem", textAlign: "justify" }}>
                      {event.content}
                    </p>
                  </VerticalTimelineElement>
                )}
              </>
            );
          })}

          <VerticalTimelineElement
            icon={
              <img className="event_img" src="photos/events/tl5.png" alt="" />
            }
            // icon={<StarIcon />}
          />
        </VerticalTimeline>
      </div>

      {/* for small screen */}

      <div className="past_events_smallscreen">
        <h1 className="heading">Hear from our student</h1>
        <hr className="hr-1" />
        <VerticalTimeline lineColor={"rgb(147, 169, 187)"} lineHeight={1.5}>
          {pastEvents.map((event) => {
            return (
              <>
                {event.id % 2 === 1 ? (
                  <VerticalTimelineElement
                    key={event.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: " rgb(50, 107, 160)",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(180, 227, 255)",
                    }}
                    date={event.date}
                    // iconStyle={{ background: '#78b0d8;;', color: '#fff' }}
                    icon={<img className="event_img" src={event.img} alt="" />}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {event.title}
                    </h3>
                    {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                    <p>{event.content}</p>
                  </VerticalTimelineElement>
                ) : (
                  <VerticalTimelineElement
                    key={event.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: " rgb(50, 107, 160)",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(180, 227, 255)",
                    }}
                    date={event.date}
                    // iconStyle={{ background: '#78b0d8;;', color: '#fff' }}
                    icon={<img className="event_img" src={event.img} alt="" />}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {event.title}
                    </h3>
                    {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                    <p>{event.content}</p>
                  </VerticalTimelineElement>
                )}
              </>
            );
          })}

          <VerticalTimelineElement
            icon={
              <img
                className="event_img"
                src="/photos/snbImages/snbLogo.jpg.png"
                alt=""
              />
            }
            // icon={<StarIcon />}
          />
        </VerticalTimeline>
      </div>
    </>
  );
}

export default Past_events;
