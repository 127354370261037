import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { events } from "../data/courseData";

import "../css/navbar.css";

function Navbar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <NavbarContainer>
      <div className="navbar">
        <a href="/" className="logo">
          <img src="/PharmacyManagementLogo.png" alt="logo" id="logo_img" />
          <span
            style={{ marginLeft: "10px", textTransform: "none" }}
            className="college-name"
          >
            SNB College Of Pharmacy And Management
          </span>
        </a>

        <div className="toggle-button" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <ul className={`navbar-dropdown ${menuActive ? "active" : ""}`}>
          <li>
            <Link to="/" className="navbar-links" onClick={toggleMenu}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/faculty" className="navbar-links" onClick={toggleMenu}>
              Faculty
            </Link>
          </li>
          <li>
            <Link to="/" className="navbar-links" onClick={toggleMenu}>
              Courses
            </Link>
            <div className="dropdown">
              {events?.map((course) => (
                <Link
                  key={course.id}
                  to={{
                    pathname: `/course-details/${course.id}`,
                  }}
                  state={course}
                  className="navbar-links"
                  onClick={toggleMenu}
                >
                  <span>{course.title}</span>
                </Link>
              ))}
            </div>
          </li>
          {/* <li>
            <Link to="/" className="navbar-links" onClick={toggleMenu}>
              GALLERY
            </Link>
          </li> */}
          <li>
            <Link
              to="/affiliaton"
              className="navbar-links"
              onClick={toggleMenu}
            >
              Approval
            </Link>
          </li>

          <li>
            <Link to="/contact" className="navbar-links" onClick={toggleMenu}>
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="/anti-ragging-help"
              className="navbar-links"
              onClick={toggleMenu}
            >
              Anti-Ragging Help Desk
            </Link>
          </li>
        </ul>
      </div>
    </NavbarContainer>
  );
}

const NavbarContainer = styled.div`
  .toggle-button {
    display: none; /* Hide toggle button by default */
  }

  /* Media query for small screens */
  @media (max-width: 768px) {
    .toggle-button {
      display: block; /* Show toggle button */
      cursor: pointer;
    }

    .navbar-dropdown {
      display: none; /* Hide dropdown menu by default */
      position: absolute;
      top: 80px; /* Adjust this value based on your design */
      left: 0;
      width: 100%;
      background-color: #333;
      padding: 10px 0;
      text-align: center;
    }

    .navbar-dropdown.active {
      display: block; /* Show dropdown menu when active */
    }

    .navbar-dropdown li {
      margin-bottom: 10px;
    }

    .navbar-dropdown li a {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      display: block;
      padding: 10px;
      transition: all 0.3s ease;
    }

    .navbar-dropdown li a:hover {
      background-color: #555;
    }
  }
`;

export default Navbar;
