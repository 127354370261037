import React from "react";
import { useLocation } from "react-router-dom";

import "../css/coursedetails.css";
import Navbar from "./Navbar";

// Assuming you're using a separate CSS file

function CourseDetails() {
  const location = useLocation();
  const { title, img, description, careerOpportunities, courseObjectives } =
    location.state;

  return (
    <>
      <Navbar />
      <div className="course-details-container">
        <div
          className="course-header"
          style={{
            backgroundColor: "#414a4c",
            borderRadius: "14px",
            marginTop: "60px",
          }}
        >
          <img
            src={img}
            alt={title}
            className="course-image"
            style={{ paddingTop: "20px" }}
          />
          <h1>{title}</h1>
          <hr className="hr-1" />
        </div>
        <div className="course-content">
          <h2 style={{ color: "rgb(124, 170, 219)" }}>Detail</h2>
          <p>{description}</p>

          <h2 style={{ color: "rgb(124, 170, 219)" }}>Career Opportunities</h2>
          <ul>
            {careerOpportunities.map((opportunity, index) => (
              <li key={index}>{opportunity}</li>
            ))}
          </ul>

          <h2 style={{ color: "rgb(124, 170, 219)" }}>Course Objectives</h2>
          <ul>
            {courseObjectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
